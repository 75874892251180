export const experienceData = [
    {
        id: 1,
        company: 'Dhindo Cricket',
        jobtitle: 'Founder / Content-Writer & Graphic Designer',
        startYear: '2021',
        endYear: 'Present'
    },
    // {
    //     // id: 2,
    //     // company: 'Chitwan Print House',
    //     // jobtitle: 'Graphic Designer',
    //     // startYear: '2020',
    //     // endYear: '2021'
    // },
]